var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          on: { "tab-click": _vm.handleClickTab },
          model: {
            value: _vm.activeTabName,
            callback: function ($$v) {
              _vm.activeTabName = $$v
            },
            expression: "activeTabName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Chu kỳ dự toán", name: "cycleMoneyTab" } },
            [
              _c(
                "el-form",
                {
                  ref: "cycleMoneyData",
                  attrs: {
                    "label-width": "140px",
                    model: _vm.cycleMoneyData,
                    "label-position": "left",
                  },
                },
                [
                  _c("h2", [_vm._v("1. Áp dụng cho các khoản thu học phí")]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Chọn loại", prop: "typeFees" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.cycleMoneyData.typeFees,
                            callback: function ($$v) {
                              _vm.$set(_vm.cycleMoneyData, "typeFees", $$v)
                            },
                            expression: "cycleMoneyData.typeFees",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "default" } }, [
                            _vm._v(
                              "Theo mặc định hệ thống (Tính từ ngày đầu tháng)"
                            ),
                          ]),
                          _c("el-radio", { attrs: { label: "custom" } }, [
                            _vm._v(
                              "Theo thiết lập nhà trường (Tính theo ngày cấu hình)"
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.cycleMoneyData.typeFees == "custom"
                    ? _c(
                        "span",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Chọn khoảng",
                                prop: "rangeFees",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.cycleMoneyData.rangeFees,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.cycleMoneyData,
                                        "rangeFees",
                                        $$v
                                      )
                                    },
                                    expression: "cycleMoneyData.rangeFees",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    { attrs: { label: "range1" } },
                                    [_vm._v("Khoảng 1 (Xem mô tả bên dưới)")]
                                  ),
                                  _c(
                                    "el-radio",
                                    {
                                      staticStyle: { "margin-left": "96px" },
                                      attrs: { label: "range2" },
                                    },
                                    [_vm._v("Khoảng 2 (Xem mô tả bên dưới)")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { display: "inline-block" },
                              attrs: {
                                label: "Ngày bắt đầu (tháng trước)",
                                prop: "startDateFees",
                              },
                            },
                            [
                              _c("el-input-number", {
                                attrs: { min: 1, max: 28 },
                                model: {
                                  value: _vm.cycleMoneyData.startDateFees,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.cycleMoneyData,
                                      "startDateFees",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "cycleMoneyData.startDateFees",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: {
                                display: "inline-block",
                                "margin-left": "153px",
                              },
                              attrs: {
                                label: "Ngày kết thúc (tháng kế tiếp)",
                                prop: "endDateFees",
                              },
                            },
                            [
                              _c("el-input-number", {
                                attrs: { min: 1, max: 28 },
                                model: {
                                  value: _vm.cycleMoneyData.endDateFees,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.cycleMoneyData,
                                      "endDateFees",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "cycleMoneyData.endDateFees",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("h2", [_vm._v("2. Áp dụng cho các khoản công lương")]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Chọn loại", prop: "typeSalary" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.cycleMoneyData.typeSalary,
                            callback: function ($$v) {
                              _vm.$set(_vm.cycleMoneyData, "typeSalary", $$v)
                            },
                            expression: "cycleMoneyData.typeSalary",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "default" } }, [
                            _vm._v(
                              "Theo mặc định hệ thống (Tính từ ngày đầu tháng)"
                            ),
                          ]),
                          _c("el-radio", { attrs: { label: "custom" } }, [
                            _vm._v(
                              "Theo thiết lập nhà trường (Tính theo ngày cấu hình)"
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.cycleMoneyData.typeSalary == "custom"
                    ? _c(
                        "span",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Chọn khoảng",
                                prop: "rangeSalary",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.cycleMoneyData.rangeSalary,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.cycleMoneyData,
                                        "rangeSalary",
                                        $$v
                                      )
                                    },
                                    expression: "cycleMoneyData.rangeSalary",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    { attrs: { label: "range1" } },
                                    [_vm._v("Khoảng 1 (Xem mô tả bên dưới)")]
                                  ),
                                  _c(
                                    "el-radio",
                                    {
                                      staticStyle: { "margin-left": "96px" },
                                      attrs: { label: "range2" },
                                    },
                                    [_vm._v("Khoảng 2 (Xem mô tả bên dưới)")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { display: "inline-block" },
                              attrs: {
                                label: "Ngày bắt đầu (tháng trước)",
                                prop: "startDateSalary",
                              },
                            },
                            [
                              _c("el-input-number", {
                                attrs: { min: 1, max: 28 },
                                model: {
                                  value: _vm.cycleMoneyData.startDateSalary,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.cycleMoneyData,
                                      "startDateSalary",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "cycleMoneyData.startDateSalary",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: {
                                display: "inline-block",
                                "margin-left": "153px",
                              },
                              attrs: {
                                label: "Ngày kết thúc (tháng kế tiếp)",
                                prop: "endDateSalary",
                              },
                            },
                            [
                              _c("el-input-number", {
                                attrs: { min: 1, max: 28 },
                                model: {
                                  value: _vm.cycleMoneyData.endDateSalary,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.cycleMoneyData,
                                      "endDateSalary",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "cycleMoneyData.endDateSalary",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("h2", [
                    _vm._v("3. Kết chuyển số dư thanh toán hóa đơn học phí"),
                  ]),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "Chọn loại", prop: "transferMoneyType" },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.cycleMoneyData.transferMoneyType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.cycleMoneyData,
                                "transferMoneyType",
                                $$v
                              )
                            },
                            expression: "cycleMoneyData.transferMoneyType",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "MONEY_WALLET" } }, [
                            _vm._v("Sử dụng ví"),
                          ]),
                          _c("el-radio", { attrs: { label: "MONEY_MONTH" } }, [
                            _vm._v("Chuyển sang tháng kế tiếp"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "350px" },
                  attrs: {
                    type: "success",
                    size: "medium",
                    loading: _vm.loadingButtonCycleMoney,
                    mini: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.updateCycleMoneyMethod()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-check" }),
                  _c("span", [_vm._v(_vm._s(_vm.$t("button.save")))]),
                ]
              ),
              _c("br"),
              _c("br"),
              _c("div", { staticStyle: { color: "red" } }, [
                _vm._v(
                  "\n        Mô tả khoảng: Ví dụ thực hiện khoản tháng 8 và ngày bắt đầu, kết thúc\n        lần lượt là 10 và 10: Khi đó số dự toán được tính theo\n        "
                ),
                _c("div", [
                  _vm._v("Chọn khoảng 1: Từ ngày 10/7 đến hết ngày 9/8"),
                ]),
                _c("div", [
                  _vm._v("Chọn khoảng 2: Từ ngày 10/8 đến hết ngày 9/9"),
                ]),
                _c("div", [
                  _vm._v(
                    "\n          Các khoản trả lại tự động lùi đi 1 chù kỳ so với khoảng ngày đã chọn\n        "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "Cấu hình quỹ", name: "tabName1" } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loadingData,
                      expression: "loadingData",
                    },
                  ],
                  ref: "multipleTable",
                  attrs: {
                    "empty-text": _vm.textTable,
                    "element-loading-text": _vm.$tableLoading,
                    "element-loading-spinner": "el-icon-loading",
                    "element-loading-background": "rgba(255,255,255, 0)",
                    "highlight-current-row": "",
                    data: _vm.responseList1,
                    "cell-style": _vm.tableRowStyle,
                    "header-cell-style": _vm.tableHeaderColor,
                    border: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      fixed: "",
                      label: "STT",
                      width: "50",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "170",
                      fixed: "",
                      align: "center",
                      label: "Năm",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.year))]),
                            _c("div", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm._f("formatDate")(scope.row.startDate)
                                  ) +
                                  " -\n              " +
                                  _vm._s(
                                    _vm._f("formatDate")(scope.row.endDate)
                                  ) +
                                  "\n            "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Trạng thái",
                      align: "center",
                      "min-width": "90",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm.checkPermission(["schoolConfig_finance_update"])
                              ? _c("el-checkbox", {
                                  on: {
                                    change: function ($event) {
                                      return _vm.changeLockedMethod(scope.row)
                                    },
                                  },
                                  model: {
                                    value: scope.row.locked,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "locked", $$v)
                                    },
                                    expression: "scope.row.locked",
                                  },
                                })
                              : _c("span", [
                                  scope.row.locked
                                    ? _c("span", [_vm._v("Đã khóa")])
                                    : _vm._e(),
                                  _c("span", [_vm._v("Chưa khóa")]),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Số dư đầu kỳ",
                      align: "right",
                      "min-width": "130",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.moneyStart == 0
                              ? _c("span", [_vm._v("0")])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatCurrency")(
                                        scope.row.moneyStart
                                      )
                                    )
                                  ),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Tổng tiền thu",
                      align: "right",
                      "min-width": "130",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.moneyIn == 0
                              ? _c("span", [_vm._v("0")])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatCurrency")(
                                        scope.row.moneyIn
                                      )
                                    )
                                  ),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Tổng tiền chi",
                      align: "right",
                      "min-width": "130",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.moneyOut == 0
                              ? _c("span", [_vm._v("0")])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatCurrency")(
                                        scope.row.moneyOut
                                      )
                                    )
                                  ),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Tổng thu - Tổng chi",
                      align: "right",
                      "min-width": "130",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.moneyIn - scope.row.moneyOut == 0
                              ? _c("span", [_vm._v("0")])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatCurrency")(
                                        scope.row.moneyIn - scope.row.moneyOut
                                      )
                                    )
                                  ),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Số dư cuối kỳ",
                      align: "right",
                      "min-width": "130",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.moneyStart +
                              (scope.row.moneyIn - scope.row.moneyOut) ==
                            0
                              ? _c("span", [_vm._v("0")])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatCurrency")(
                                        scope.row.moneyStart +
                                          (scope.row.moneyIn -
                                            scope.row.moneyOut)
                                      )
                                    )
                                  ),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm.checkPermission(["schoolConfig_finance_update"])
                    ? _c("el-table-column", {
                        attrs: {
                          fixed: "right",
                          label: "Tác vụ",
                          width: "120",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        disabled: scope.row.locked
                                          ? true
                                          : false,
                                        type: "success",
                                        size: "mini",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.updateRowMethod(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("Sửa")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1551315366
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: "Thông tin ngân hàng",
                name: "tabName2",
                model: _vm.dataInput,
              },
            },
            [
              _c(
                "div",
                { staticClass: "bank" },
                [
                  _c(
                    "p",
                    {
                      staticStyle: {
                        "margin-right": "15px",
                        "margin-top": "50px",
                        color: "black",
                        "font-size": "20px",
                      },
                    },
                    [
                      _vm._v(
                        "\n          Nội dung thanh toán hiển thị trên app phụ huynh(Khi thao tác thanh\n          toán):\n        "
                      ),
                    ]
                  ),
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder:
                        "Nhập nội dung thanh toán hiển thị trên app phụ huynh",
                    },
                    model: {
                      value: _vm.dataInput.bankInfo,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataInput, "bankInfo", $$v)
                      },
                      expression: "dataInput.bankInfo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "bank" },
                [
                  _c(
                    "p",
                    {
                      staticStyle: {
                        "margin-right": "15px",
                        "margin-top": "50px",
                        color: "black",
                        "font-size": "20px",
                      },
                    },
                    [
                      _vm._v(
                        "\n          Đính kèm thông báo học phí qua App phụ huynh(Khi thao tác hiển thị):\n        "
                      ),
                    ]
                  ),
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder:
                        "Nhập nội dung đính kèm thông báo học phí qua App phụ huynh",
                    },
                    model: {
                      value: _vm.dataInput.feesParent,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataInput, "feesParent", $$v)
                      },
                      expression: "dataInput.feesParent",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "bank" },
                [
                  _c(
                    "p",
                    {
                      staticStyle: {
                        "margin-right": "15px",
                        "margin-top": "50px",
                        color: "black",
                        "font-size": "20px",
                      },
                    },
                    [
                      _vm._v(
                        "\n          Ghi chú trên phiếu thu học phí:\n        "
                      ),
                    ]
                  ),
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "Nhập thời gian thanh toán",
                    },
                    model: {
                      value: _vm.dataInput.expired,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataInput, "expired", $$v)
                      },
                      expression: "dataInput.expired",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "bank" },
                [
                  _c(
                    "p",
                    {
                      staticStyle: {
                        "margin-right": "15px",
                        "margin-top": "50px",
                        color: "black",
                        "font-size": "20px",
                      },
                    },
                    [
                      _vm._v(
                        "\n          Ghi chú trên phiếu công lương:\n        "
                      ),
                    ]
                  ),
                  _c("el-input", {
                    attrs: { type: "textarea", placeholder: "Nhập ghi chú" },
                    model: {
                      value: _vm.dataInput.note,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataInput, "note", $$v)
                      },
                      expression: "dataInput.note",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _vm.checkPermission(["schoolConfig_finance_update"])
                    ? _c(
                        "el-button",
                        {
                          staticStyle: {
                            "margin-top": "10px",
                            float: "right",
                            "border-radius": "0",
                          },
                          attrs: {
                            loading: _vm.loadingButton,
                            type: "success",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.saveBankInfo()
                            },
                          },
                        },
                        [_vm._v("Lưu")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c("UpdateCashBookDialog", {
        ref: "UpdateCashBookDialog",
        attrs: { dialogVisible: _vm.showUpdateDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.closeUpdateDialog()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
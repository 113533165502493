var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Cập nhật số dư đầu kì năm " + _vm.year,
            visible: _vm.dialogVisible,
            width: "600px",
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            top: "10vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataInput",
              attrs: {
                model: _vm.dataInput,
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "moneyStart", label: "Số dư đầu kỳ" } },
                [
                  _c("el-currency-input", {
                    model: {
                      value: _vm.dataInput.moneyStart,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataInput, "moneyStart", $$v)
                      },
                      expression: "dataInput.moneyStart",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-close" }),
                  _c("span", [_vm._v(_vm._s(_vm.$t("button.close")))]),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.loadingButton,
                    type: "success",
                    size: "medium",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-check" }),
                  _c("span", [_vm._v(_vm._s(_vm.$t("button.save")))]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }